<template>
    <div id="header">
      <!-- <marquee style="color:red;background:#fff;margin-top:5px">Dear Valued Customer, on Saturday 18th of March 2023, due to the elections, our stores will not open for in-store purchases, deliveries and pickup. We will reopen our stores for walk-in customers on Sunday 19th of March 2023 and continue our pickup and delivery services on Monday 20th of March 2023. We appreciate your understanding and thank you for choosing Market Square.</marquee> -->
      <!--<FreeDeliveryPrompt/>-->
      <!--<FreeDelivery/>-->
      <div id="topnav"  class="content-desktop">
        <div class=" container">
          <div class="d-flex justify-content-between">
            <ul class="row float-left">
             <!-- <li class="navlink  d-sm-block d-md-none">
                <a
                  id="openbtn"
                  href=""
                  class=""
                  @click.prevent="openNav()"
                >☰</a>
              </li>-->
              <li
                v-if="$store.getters.isLoggedIn"
                id="user"
                class="menu-link dropdown d-sm-block d-md-none"
                style="position: relative;left: 36%;"
              >
                <a
                  id="username"
                  href=""
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="text-uppercase">{{user.fullname}}</span>
                  <span id="userpoint">0 points</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="/account">
                      <span class="material-icons mr-2 ml-1">
                        person
                      </span>
                      <span class="text ml-4">My Account</span>
                    </a>
                  </li>
                  <!-- <li class="">
                    <a href="/newwallet">
                      <span class="material-icons mr-2 ml-1">
                        person
                      </span>
                      <span class="text ml-4">My Wallet</span>
                    </a>
                  </li> -->
                  <li class="divider"></li>
                  <li>
                    <a href="/orders">
                      <span class="material-icons mr-2 ml-1">
                        shopping_basket
                      </span>
                      <span class="text ml-4">My Orders</span>
                    </a>
                  </li>
                  <li class="divider"></li>
                  <li @click.prevent='logOut()'>
                    <a
                      href=""
                      class="text-danger"
                    >
                      <span class="material-icons mr-2 ml-1">
                        exit_to_app
                      </span><span class="text ml-4">Logout</span></a>
                  </li>
                  <li class="divider"></li>

                </ul>
              </li>
              <li class=" navlink text-capitalize mt-md-0 mt-3">
                <!-- store not selected -->
                <a
                  v-if='$store.getters.isStoreSet==false'
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                >
                  <img
                    src="../assets/img/store-icon-sm.png"
                    alt=""
                  >
                  Select Store

                </a>
                <!-- store selected -->
                <a
                  v-else
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                >
                  <img
                    src="../assets/img/store-icon-sm.png"
                    alt=""
                    class="mr-1"
                  >
                  <span v-if="this.$store.getters.store.mode =='Delivery'">Delivering to <b>{{this.$store.getters.area}}</b> from <b>{{this.$store.getters.store.name}}</b> Store</span>
                  <span v-else>Pickup from <b>{{this.$store.getters.store.name}}</b> Store</span>

                  <a
                    v-if="$route.name !==('Checkout') && $route.name !==('StoreLocator')"
                    href=""
                    class="badge badge-pill badge-light ml-1"
                    data-toggle="modal"
                    data-target="#store"
                  >Change</a>
                </a>
              </li>

            </ul>
            <ul class="d-md-flex d-none ">
              <li class=" navlink"><a href="/storelocator">
                  <span class="material-icons mr-md-2">
                    location_on
                  </span>

                  Store Locator </a></li>
              <!-- <li class=" navlink"><a href="/giftcard">
                  <img
                    src="../assets/img/giftcard-icon.png"
                    alt=""
                  >
                  Gift cards </a></li> -->
              <li class=" navlink">
                <a href="/contact"> Contact Us </a>
              </li>

            </ul>
            <ul
              v-if="$store.getters.isLoggedIn ==false"
              class="d-md-flex d-none "
            >
              <li class=" navlink ">
                <a href="/login"> Sign in </a>
              </li>
              <li class=" navlink mr-0"><a
                  id="register"
                  href="/register"
                > Register </a>
              </li>
            </ul>
            <ul
              class="d-md-flex d-none "
              v-else
              id="user"
            >
              <li class="menu-link dropdown ">
                <a
                  id="username"
                  href=""
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="text-uppercase">{{user.fullname}}</span>
                  <span id="userpoint">0 points</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="/account">
                      <span class="material-icons mr-2 ml-1">
                        person
                      </span>
                      <span class="text ml-4">My Account</span>
                    </a>
                  </li>
                  <!-- <li class="">
                    <a href="/newwallet">
                      <span class="material-icons mr-2 ml-1">
                        person
                      </span>
                      <span class="text ml-4">My Wallet</span>
                    </a>
                  </li> -->
                  <li class="divider"></li>
                  <li>
                    <a href="/orders">
                      <span class="material-icons mr-2 ml-1">
                        shopping_basket
                      </span>
                      <span class="text ml-4">My Orders</span>
                    </a>
                  </li>
                  <li class="divider"></li>
                  <li @click.prevent='logOut()'>
                    <a
                      href=""
                      class="text-danger"
                    >
                      <span class="material-icons mr-2 ml-1">
                        exit_to_app
                      </span><span class="text ml-4">Logout</span></a>
                  </li>
                  <li class="divider"></li>

                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="middlenav" class="content-desktop">
        <div class="container d-flex justify-content-between">
          <div id="logo">
            <a href="/home">
              <img
                class="d-none d-lg-block"
                src="../assets/img/logo-m.png"
                alt="Marketsquare Logo"
              > <img
                class=" d-md-block d-lg-none"
                src="../assets/img/logo_mobile.png"
                alt="Marketsquare Logo"
              >
            </a>
          </div>
          <div
            v-if="$store.getters.showSearch==true"
            id="search"
            class="d-none d-md-block "
          >
            <Search/>

            <!-- </Search> -->
            <!-- <input
              @keyup.enter="search()"
              type="text"
              v-model="searchQuery"
              placeholder="Search your products from here"
            >
            <i
              @click="search()"
              style="cursor:pointer"
              class="material-icons"
            >search</i> -->
          </div>
          <!-- <div
            v-if="$store.getters.isLoggedIn"
            id="cart-icon"
            class="d-flex dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div id="cart-circle">
              <span class="material-icons">
                local_mall
              </span>
              <span id="cart-count">{{cart.length}}</span>
            </div>
            <span
              id="cart-price"
              class="ml-2"
            >₦{{formatPrice(cart_total)}}</span>
          </div> -->
          <div class="dropdown-menu cart-dropdown">
            <div v-for="row in cart" class="dropdown-item">
              <div class="row">
                <div class="col-3 icon">
                  <img
                    src="../assets/img/store-icon.png"
                    alt=""
                  >
                </div>
                <div class="col-9">
                  <h5 class="storename">{{getStore(row.storeid)}}</h5>
                  <p class="cart-info">{{row.quantity}} item(s) in Cart</p>
                  <p class="price">₦{{formatPrice(row.total_price)}}</p>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button
                  data-backdrop="false"
                  @click.prevent='store=row;closeModal()'
                  data-toggle='modal'
                  data-target="#fulfillmentmode"
                  id="placeorder-btn"
                >Place Order</button>
                <!-- <button id="view-btn">View Store</button> -->
              </div>

            </div>
            <div class="dropdown-divider"></div>

          </div>
        </div>
      </div>
      <div v-if="$route.name !== 'LandingPage'"id="menu-bar"class="d-none  d-md-block" >
      <div class="container">
          <ul  id="menu" class="d-flex justify-content-between">
        
           
            <li class="menu-link dropdown">
              <a  v-if="category.length > 0" href="" data-toggle="dropdown">
                <i class="fa fa-bars" style="margin-right: 91px;margin-top: -5px;font-size: 10px;color: gray;"></i> View Categories
              </a>
              <ul class="dropdown-menu" id="category-nav">
                <li v-for="(promo, index) in category" :key="index" ><a :href="promo == 'prepared food' ? '/preparedfood' : '/category/'+promo">{{promo.charAt(0).toUpperCase() + promo.slice(1) }}</a></li>
              </ul>
            </li>
                
             <li  class="menu-link">
                <img src="../assets/img/prepared.png" alt="" class="mr-1" style="height:18px"
                  >
               <a href="/preparedfood"> Prepared food  
                  <button class="new"> New
                    <span class="bteffect">
                      
                    </span>
                  </button>
               </a>
            </li>
          <!-- <li class="menu-link"><a href="/valentine/story">Valentine Story</a></li> -->
            <li class="menu-link dropdown">
              <a
                v-if="promotions.length > 0"
                href=""
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Specials</a>
              <ul class="dropdown-menu">
                <li class="divider"><a href="/promotions/Specials"> All </a></li>
                <li v-for="(promo, index) in promotions" :key="index" >
                <a href="/category/combos" v-if="promo == 'delight mixes & cards'">
                   Combos & Gift Cards
                  </a>
                  <a :href="'/category/'+ promo" v-else>
                    {{promo == 'combos' ? 'Combos': promo.charAt(0).toUpperCase() + promo.slice(1)}}
                    <!-- {{promo.charAt(0).toUpperCase() + promo.slice(1) }} -->
                  </a>
                </li>
              </ul>
            </li>
                
           <!-- <li v-if="showCategory('bakery')" class="menu-link"><a href="/category/combos"> Combos & Gift Cards</a></li>-->

            <li v-if="showCategory('bakery')" class="menu-link"><a href="/category/bakery"> Bakery</a></li>


            <li class="menu-link dropdown" v-if="showCategory('groceries') || showCategory('confectioneries') || showCategory('beverages') || showCategory('breakfast cereal')">
              <a
                href=""
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Groceries</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('groceries')" ><a href="/category/groceries" >Groceries</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('confectioneries')"><a href="/category/confectioneries" >Confectioneries</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('beverages')" ><a href="/category/beverages">Beverages</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('breakfast cereal')"><a href="/category/breakfast cereal" >Breakfast Cereal</a></li>

              </ul>
            </li>

            <li class="menu-link dropdown" v-if="showCategory('fresh produce') || showCategory('commodities') || showCategory('perishables') || showCategory('butchery')">
              <a
                href="/category/food"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Food</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('fresh produce')"><a href="/category/fresh produce">Fresh Produce</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('commodities')"><a href="/category/commodities">Commodities</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('perishables')"><a href="/category/perishables">Perishables</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('butchery')"><a href="/category/butchery" >Butchery</a></li>
              </ul>
            </li>
            <li class="menu-link dropdown" v-if="showCategory('spirits') || showCategory('water')">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Drinks</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('spirits')"><a href="/category/spirits">Spirits</a></li>
                <li class="divider"></li> 
                <li v-if="showCategory('water')"><a href="/category/water">Water</a></li>
              </ul>
            </li>
            <li class="menu-link dropdown" v-if="showCategory('medicare') || showCategory('toiletries')">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Health & Beauty</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('medicare')"><a href="/category/medicare" >Medicare</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('toiletries')"><a href="/category/toiletries" >Toiletries</a></li>
                <li class="divider"></li>

              </ul>
            </li>
            <li class="menu-link dropdown" v-if="showCategory('household') || showCategory('accessories') ||  showCategory('sda')">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Appliances & Accessories</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('household')"><a href="/category/household" >Household Items</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('accessories')"><a href="/category/accessories" >Accessories</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('sda')"><a href="/category/sda" >SmallDomestiic Appliances</a></li>
              </ul>
            </li>
            <li class="menu-link dropdown" v-if="showCategory('automobile') || showCategory('baby products') || showCategory('pet products') || showCategory('bms') || showCategory('general')">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
              >Others</a>
              <ul class="dropdown-menu">
                <li v-if="showCategory('automobile')"><a href="/category/automobile" >Automobile</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('baby products')"><a href="/category/baby products" >Baby Products</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('pet products')"><a href="/category/pet products" >Pet Products</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('bms')"><a href="/category/bms" >BMS</a></li>
                <li class="divider"></li>
                <li v-if="showCategory('general')"><a href="/category/general" >General</a></li>
              </ul>
            </li>
            
          </ul>
        </div>
      </div>

           <!-- mobile --> <!-- <button class="openbtn d-sm-block d-md-none" @click="openNav()" >☰</button> -->
      <div id="mySidepanel" class="sidepanel d-sm-block d-md-none" style="background-color:white ">
       <div style="height:75px">
       <div id="topnav"  style="margin-top: -61px; height:54px " >
       <ul class="">
           <li
                <a href="/home"> <img class=" d-md-block d-lg-none" src="../assets/img/MSlogowhite.png" alt="Marketsquare Logo" style="margin-left:-26px; width: 130px;margin-top: -1px;" >
                </a>
            </li>
            
            <li  class="nm ">
             <img
                    src="../assets/img/stores.png"
                    alt=""
                    class="mr-1"
                    style="height: 23px;" >
                  <span v-if="this.$store.getters.store.mode =='Delivery'" style = "color:white; text-align: center;">
                <!--  Delivering to <b>{{this.$store.getters.area}}</b> 
                  from--> <b style=" text-decoration: underline">{{this.$store.getters.store.name}}</b>
                   </span> 
                  <span v-else><!--Pickup from-->
                   <b>{{this.$store.getters.store.name}}</b> 
                   </span>
            </li> 

             <li style="list-style:none">
          <a  href="javascript:void(0)" class="closebtn" @click.prevent="closeNav()" style="color:white;top: 6px;margin-right: -23px;font-size: 24px;" >×</a>
          </li>
            
        </ul>
      </div>
      </div>
       <div style=" margin-top: 10px;" >
        <div class="row" style="justify-content: center; margin-left:-150px; color:#060669; text-align: center;">
          <a  href="/login"v-if="!$store.getters.isLoggedIn" class=" sidemenu">
          <div class="btn  butt" style="margin-right: -21px;" > Sign In </div>
          </a>
          <a  href="/Register"class="register sidemenu" v-if="!$store.getters.isLoggedIn">
          <div class="btn butt" > Register</div> </a>
          </div>
          </div>
           <div class="row" style="margin-top: -48px;margin-left: 10px;" v-if="$store.getters.isLoggedIn" id="user">
           <div id = "namebox"> 
           <p  class ="init" ><b>{{Ab(user.fullname)}} </b> </p>
           </div>
           <p style="margin-top:86px"><b> {{user.fullname}}</b></p>
           </div>
           <div style="margin-top: 10px">
           <div class="row" style="justify-content: center; color:#060669; text-align: center;" v-if="$store.getters.isLoggedIn" id="user">
          <a class="lnk" href="/account"> <div class="btn  button" style="margin-right: 21px;">
            <span class="material-icons mr-2 ml-1" >person</span> My Account </div></a>
            <a class="lnk"style="margin-right: 27px;"href="/orders"> <div class="btn button" >
             <span class="material-icons mr-2 ml-1">shopping_basket</span> My Orders</div></a>
              </div>
              </div>
      <hr/>
          <a @click.prevent='toggleDropdown'class="dropdown-btn sidemenu">
          <b style="margin-left: -22px;"> Categories</b>
          </a>
          
          <div class="dropdown-container">
             <a href="/preparedfood"> Prepared food 
                <button class="new">New
                  <span class="bteffect">
                  </span>
                </button></a>
            <a  @click.prevent='toggleDropdown'  class="dropdown-btn sidemenu">Specials</a>
             <div class="dropdown-container">
             <a href="/promotions/Specials" class="sidemenu"> All </a>
             <a v-for="(promo, index) in promotions" :key="index"  :href="'/category/'+promo" class="sidemenu">{{promo.charAt(0).toUpperCase() + promo.slice(1) }}</a>
             </div>
             <a href="/category/combos" class="sidemenu" >Combos </a>
              
         <a href="/category/bakery" class="sidemenu">Bakery </a>
          <a v-if="showCategory('groceries') || showCategory('confectioneries') || showCategory('beverages') || showCategory('breakfast cereal')"
           href="/category/groceries" @click.prevent='toggleDropdown' class="dropdown-btn sidemenu" >Groceries </a>
          <div class="dropdown-container">
           <a href="/category/groceries" v-if="showCategory('groceries')">Groceries</a>
           <a href="/category/confectioneries" v-if="showCategory('confectioneries')">Confectioneries</a>
           <a href="/category/beverages" v-if="showCategory('beverages')">Beverages</a>
           <a href="/category/breakfast cereals" v-if="showCategory('breakfast cereals')">Breakfast Cereal</a>
            </div>
            <a v-if="showCategory('fresh produce') || showCategory('commodities') || showCategory('perishables') || showCategory('butchery')"
            href="#"
            @click.prevent='toggleDropdown'
             class="dropdown-btn sidemenu">Food</a>
             <div class="dropdown-container">
           <a href="/category/fresh produce" v-if="showCategory('fresh produce')"> Fresh Produce</a>
           <a href="/category/commodities" v-if="showCategory('commodities')">Commodities</a>
           <a href="/category/perishables" v-if="showCategory('perishables')">Perishables</a>
           <a href="/category/butchery" v-if="showCategory('butchery')">Butchery</a>
           <a href="/category/condiments" v-if="showCategory('condiments')">Condiments</a>
            </div>
           <a v-if="showCategory('spirits') || showCategory('water')"
           href="#" @click.prevent='toggleDropdown' class="dropdown-btn sidemenu">Drinks</a>
           <div class="dropdown-container">
          <a href="/category/spirits" v-if="showCategory('spirits')">Spirits</a>
          <a href="/category/water" v-if="showCategory('water')">Water</a>

        </div>
        <a
          v-if="showCategory('medicare') || showCategory('toiletries')"
          href="#"
          @click.prevent='toggleDropdown'
          class="dropdown-btn sidemenu"
        >Health & Beauty </a>
        <div class="dropdown-container">
          <a href="/category/medicare" v-if="showCategory('medicare')">Medicare</a>
          <a href="/category/toiletries" v-if="showCategory('toiletries')">Toiletries</a>

        </div>
         <a
          v-if="showCategory('household') || showCategory('accessories') ||  showCategory('sda')"
          href="#"
          @click.prevent='toggleDropdown'
          class="dropdown-btn sidemenu"
        >Appliances & Accessories </a>
        <div class="dropdown-container">
          <a href="/category/household" v-if="showCategory('household')">Household Items</a>
          <a href="/category/accessories" v-if="showCategory('accessories')">Accessories</a>
          <a href="/category/sda" v-if="showCategory('sda')">Small Doestic Appliances</a>

        </div>
         <a
          v-if="showCategory('automobile') || showCategory('baby products') || showCategory('pet products') || showCategory('bms') || showCategory('general')"
          href="#"
          @click.prevent='toggleDropdown'
          class="dropdown-btn sidemenu"
        >Others </a>
        <div class="dropdown-container">
          <a href="/category/automobile" v-if="showCategory('automobile')">Automobile</a>
          <a href="/category/baby products" v-if="showCategory('baby products')">Baby Products</a>
          <a href="/category/pet products" v-if="showCategory('pet products')">Pet Products</a>
          <a href="/category/bms" v-if="showCategory('bms')">BMS</a>
          <a href="/category/general" v-if="showCategory('general')">General</a>

        </div>
          </div>
           <hr>
         <div >
         <ul class="row">
           <img
             src="../assets/img/telephone.png"
                    alt=""
                    style=" height: 20px; margin-top: 12px;margin-left: -15px; margin-right: -25px;"/>
                   <a href="/contact" class="sidemenu" >Contact Us</a>
                    </ul>
                      </div>
                       <div>
                       <ul class="row">
                       <img src="../assets/img/locate.png" alt=""
                         style=" height: 20px; margin-top: 12px;margin-left: -15px; margin-right: -25px;"/>
       
                          <a  href="/storelocator" class="sidemenu" >Store Locator </a>
                          </ul>
                          </div>
                           <hr>
                           <a >
                           <b style="margin-left: -22px;color: black;"> Company</b>
                           </a>
                            <div class="" style="margin-left: -22px;">
                            <a  href="/about" class="sidemenu"> About Marketsquare </a>
            
                            </div>
                              <a >
                              <b style="margin-left: -22px;color: black;"> Product</b>
                               </a>
                             <div class="" style="margin-left: -22px">
                             <a  href="/giftcard" class="sidemenu"> Giftcard </a>
            
                               </div>
                                <a>
                            <b style="margin-left: -22px;color: black;"> Get In Touch</b>
                             </a>
                              <div class="row" style="margin-left: -22px;">
                                <a  href="https://twitter.com/marketsquarengr">
                                <img   src="../assets/img/twitter.png"  alt="" class="mr-1" /> 
                                </a>
                                <a  href="https://instagram.com/marketsquarengr" style=" margin-left: -29px;">
                                <img src="../assets/img/instagram.png"   alt="" class="mr-1" />
                                 </a>
                                 <a href="https://facebook.com/marketsquarengr" style=" margin-left: -29px;">
                                 <img src="../assets/img/facebook.png" alt="" class="mr-1" />
                                 </a>
                                 </div>
                                 <p style="margin-left: -22px;"><a href="mailto:service@sundrymarkets.ng"> service@sundrymarkets.ng 
                                </a> <a> Market Square, Nigeria</a></p>
         
                                <hr>

        <p style="margin-left: -22px;"><a> Terms of service
          </a> <a> Privacy Policy</a></p>
          <hr>
           <div
            v-if="$store.getters.isLoggedIn"
            @click.prevent='logOut()'>
                    <a href=""class="" >
                    <img style="margin-left: -15px;"src="../assets/img/exit.png"alt="" class="" />
                    <span class="text" style="margin-left: 4px;">Logout</span></a>
                  </div>
       
       
      </div>
      <div class="mobile d-sm-block d-md-none">
        <div v-if="$store.getters.showSearch==true" id="search" class="content-desktop">
            <Search :searchQuery="$route.params.search != undefined ? $route.params.search : ''"  />


          <!-- </Search> -->
          <!-- <input @keyup.enter="search()" type="text"v-model="searchQuery"placeholder="Search your products from here">
            <i @click="search()"style="cursor:pointer"class="material-icons">search</i> -->
        </div>
      </div>
      <!-- mobile end -->
      <div id="content-mobile">
      <div id="header ">
          <div id="topnav" >
          <div class=" container">
          <div class="d-flex justify-content-between">

            <ul class="row ">
              <li class="navlink  d-sm-block d-md-none">
                <a
                  id="openbtn "
                  href=""
                  class=""
                  @click.prevent="openNav()"
                ><img  src="../assets/img/more.png"
                    alt=""
                    class="mr-1" 
                    style="margin-top: 20px;margin-left:-12px "/>
                </a>
              </li>
              <li
                <a href="/home">
              <img
                class=" d-md-block d-lg-none smimg"
                src="../assets/img/MSlogowhite.png"
                alt="Marketsquare Logo"
             
              >
            </a>
               
               
              </li>
              
                  <li
              
                <div  v-if="$store.getters.showSearch==true"  id="search" style="" >
             <Searchmobile :searchQuery="$route.params.search != undefined ? $route.params.search : ''"  />
             
  
               
                </div>
              </li>
            </ul>
          </div>
        </div>
        </div>
          </div>
         <!-- <div id="topnav" class="top">
            store not selected 
         <div style="display: flex;justify-content: center;">
                <a
                  v-if='$store.getters.isStoreSet==false'
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                   >
                  <img
                    src="../assets/img/store-icon-sm.png"
                    alt=""
                  >
                  Select Store

                </a>
                 store selected 
                <a
                  v-else
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                >
                  <img
                    src="../assets/img/stores.png"
                    alt=""
                    class="mr-1"
                    style="height:15px" >
                  <span v-if="this.$store.getters.store.mode =='Delivery'" style="color: white;text-align: center;font-size: 11px;">
                <!--  Delivering to <b>{{this.$store.getters.area}}</b> 
                  from <b>{{this.$store.getters.store.name}}</b> 
                    <img
                    src="../assets/img/maps.png"
                    alt=""
                    class="mr-1"
                    style="height:15px" >
                  <b>{{this.$store.getters.area}}</b> 
                  </span>
                  <span v-else><!--Pickup from
                   <b>{{this.$store.getters.store.name}}</b>  
                    
                      <img
                    src="../assets/img/maps.png"
                    alt=""
                    class="mr-1"
                  >
                
                   <b>{{this.$store.getters.area}}</b> 
                   </span>

                  <a
                    v-if="$route.name !==('Checkout') && $route.name !==('StoreLocator')"
                    href=""
                    class="badge badge-pill badge-light ml-1"
                    data-toggle="modal"
                    data-target="#store"
                    style=" border-radius: 3px; font-size:57%"
                  >Change Location</a>
                </a>
              </li>

            </ul>
        
             </div>
              

          </div>-->

      </div>

      <!-- Product Modal -->
      <div class="modal fade"
        data-backdrop="false"
        id="fulfillmentmode"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Fulfillment Mode</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="placeOrder()">

                <v-select
                  required
                  :options="['Pickup', 'Delivery']"
                  v-model="method"
                  placeholder="Select fulfillment mode"
                  class="form-group"
                >
                </v-select>
                <v-select
                  v-if="method== 'Delivery'"
                  required
                  :options="areas"
                  v-model="area"
                  placeholder="Select Delivery Area"
                  class="form-group"
                >
                  <template #no-options="{ searching, loading }">
                    Please wait...
                  </template>
                  <span slot="no-options">Sorry, no matching options</span>
                </v-select>

                <button class="btn mx-auto text-center">Submit</button>
              </form>
            </div>
           </div>
          </div>
        </div>
      </div>
    
</template>


<script>
import * as $ from "jquery";
import Search from "@/components/Search.vue";
import Searchmobile from "@/components/Searchmobile.vue";
import FreeDelivery from  "@/components/FreeDelivery.vue";
import FreeDeliveryPrompt from  "@/components/FreeDeliveryPrompt.vue";
export default {
  name: 'TopNav',
  props: {
    msg: String
  },
  components: {Search, Searchmobile, FreeDeliveryPrompt, FreeDelivery},
  data () {
    return {
      searchQuery: '',
      
      hamper_status: 0,
      cats : {
        food : ['commodities', 'perishable', 'butchery', 'fresh produce', 'condiments'],
        appliances_acessories : ['sda', 'accessories', 'household'],
        drinks : ['water', 'spirits'],
        health_beauty : ['medicare', 'toiletries'],
        others : ['pet products', 'baby products', 'automobile', 'bms', 'general'],
        groceries : ['beverage', 'confectioneries', 'breakfast cereal', 'groceries']
      },
      
      user: {},
      cart: [],
      store: {},
      method: '',
      areas: [],
      area: '',
      promotion: [],
      category: [],
      initials:[],
    }
  },
  mounted () {
    

    window.scrollTo(0, 0)
    if (this.$route.name == 'Search') {
      this.searchQuery = this.$route.params.search
    }
    this.user = this.$store.getters.user;
    if (this.$store.getters.storesCart.length > 0) {
      this.cart = this.$store.getters.storesCart;
      // console.log(this.cart)
    }
    // this.getHamperStatus();
    // if(this.$store.getters.isStoreSet !=false) {
    //   this.fetchCategories();
    //   this.fetchPromotions();
    // }
 this.closeNav();
 this.showCategory();
 
  },
  watch: {
    method (val) {
      if (val == 'Delivery') {

        let req = {
          what: "areas",
          showLoader: false
        }
        this.$request.makeGetRequest(req)
          .then(response => {
            if (response.type == 'areas') {
              let zones = response.data.data
              let vm = this;
              zones.forEach(i => {
                i.areas.forEach(j => {
                  j.store.forEach(k => {
                    if (k == vm.store.storeid) {
                      vm.areas.push(j.area)
                    }
                  })
                })
              })
            }
          })
          .catch(error => {

            console.log(error)
          });


      }
    }

  },
  computed: {
    cart_total () {
      let total = 0;
      this.cart.forEach(i => {
        total += Number(i.total_price);
      })
      return total
    },
    categories () {
      if(this.$store.getters.categories != [] ) {
        this.category = this.$store.getters.categories;
        return this.$store.getters.categories;
      } else if(this.category != []) {    
        return this.category;
      } else {
        this.fetchCategories();
      }
    },
    promotions() {
      if(this.$store.getters.promotions != [] ) {
        return this.$store.getters.promotions.filter((val) => val != null  && val != "null" && val != "NULL");
      } else if(this.promotion != []) {
        return this.promotion.filter((val) => val != null  && val != "null" && val != "NULL");
      } else {
        this.fetchPromotions();
      }
    },
  },
  methods: {
    fetchCategories () {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getCategories') {
              this.category = response.data.data
              this.$store.dispatch('categories', response.data.data)


            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchPromotions () {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getPromotions') {
              this.promotion = response.data.data.filter((val) => val != null  && val != "Null" && val != "NULL")
              this.$store.dispatch('promotions', this.promotion);
            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    showCategory(name){
   
      
      
       return this.categories.includes(name);
    },
    getSubCategories(name) {
      return this.$store.getters.categories.filter( (val) => this.cats[name].includes(val))
    },
     setCategoryRoute (route) {
      this.$store.dispatch('setCategoryRoute', route);
    },
    getHamperStatus() {
      let req = {
          what: "hamperStatus",
          showLoader: false
        }
        this.$request.makeGetRequest(req)
          .then(response => {
            if (response.type == 'hamperStatus') {
              this.hamper_status = response.data;
              // this.$store.dispatch('hamper_status', response.data.data);
            }
          })
          .catch(error => {

            console.log(error)
          });

    },
    openNav () {
      document.getElementById("mySidepanel").style.width = "100%";
       
    },
    closeNav () {
      document.getElementById("mySidepanel").style.width = "0";
    },
    toggleDropdown (e) {
      document.querySelectorAll('.dropdown-btn').forEach(el => {

        if ((el.nextElementSibling.style.display === "block") && (el != e.target)) {
          el.classList.remove('active');
          el.nextElementSibling.style.display = "block";
        }
      })
      e.target.classList.toggle("active");
      var dropdownContent = e.target.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    },
    search () {
      if (this.$route.name !== 'Search') {
        this.$router.push(`/search/${this.searchQuery}`)
      }
      else {
        this.$router.push({ name: 'Search', params: { search: this.searchQuery } })
        // this.$parent.fetchProducts()
        this.$router.go();
      }


    },
    logOut () {
      this.$store.dispatch('setLoggedIn', false)
      this.$store.dispatch('user', {})
      this.$router.push('home');
    },
    placeOrder () {
      console.log(this.store);
      let cart_array = [];
      this.store.cartitem.forEach(i => {
        let x = {
          quantity: i.quantity,
          unit_price: i.unit_price,
          price: i.price,
          product: {
            id: i.product_id,
            name: i.name,
            img_url: i.img_url,
            price: i.sellingprice
          }
        }
        cart_array.push(x)
      })
      if (this.method == 'Delivery') {
        this.$store.dispatch("area", this.area);
      }
      let store;
      let allstores = this.$store.getters.allstores;
      allstores.forEach(i => {
        if (i.id == this.store.storeid) {
          store = i;
        }
      })
      store.mode = this.method;

      this.$store.dispatch("setStoreStatus", true);
      this.$store.dispatch("setStore", store).then(res => {
        this.$store.dispatch('addToCart', cart_array)
        $(".modal").modal("hide")
        this.$router.push({ name: 'Cart' })
      })

    },
    closeModal () {
      $(".modal").modal("hide")
    },
    getStore (id) {
      let stores = this.$store.getters.allstores;
      let name;
      stores.forEach(i => {
        if (i.id == id) {
          name = i.name;
        }
      })
      return name;
    },


    Ab(x){
   
          var str = this.user.fullname;
          var matches = str.match(/\b(\w)/g);
          //var acronym = matches.join('');
           // init.push(matches);
           // sm.push(init[0]);
       var init = matches[1]+ matches[2];
       return init;
            console.log(init);
           
    },
        
          
    
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str[0];
    },
  }
}
</script>


<style scoped>
#user li,
li#user {
  list-style: none;
  position: relative;
}
#user .dropdown-menu li a,
li#user .dropdown-menu li a {
  color: #000066;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.searchInput {
    border:none;
    background: none;
    outline:none;
    float:left;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 50px;
    width: 0px;
}
#user .dropdown-menu li:hover,
#user .dropdown-menu li:hover a,
li#user .dropdown-menu li:hover,
li#user .dropdown-menu li:hover a {
  background: #000066;
  color: #fff;
}
#user .dropdown-menu li a .material-icons,
li#user .dropdown-menu li a .material-icons {
  font-size: 15px;
  position: absolute;
  top: -2px;
}
#username {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}
.nm{
  margin-top: -35px;
    margin-left: 185px;
    font-size: 12px;
    color: white;
    list-style: none;

}
form{
    position: relative;
    top: 60%;
    left: 10%;
    transform: translate(-50%,-50%);
    transition: all 1s;
   width:100px;
    height: 40px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
}
#newf{
   position: relative;
    top: 60%;
    left: 10%;
    transform: translate(-50%,-50%);
    transition: all 1s;
   width:204px;
    height: 40px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
}
input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
}

.fa{
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

form:hover,
form:valid{
    width: 250px;
    cursor: pointer;
}

form:hover input,
form:valid input{
    display: block;
}

form:hover .fa,
form:valid .fa{
    background:#E50808;
    color: white;
}

.button{
  background:white;
    border-color: #e7e7e7;
    color:#060669;
}

.butt{
  background:#000066;
    border-color: #000066;
    color:white;
    width:140px;
    margin-right:-90px;
    }
.init{
  color: #000066;
  font-size:50px;
  text-align:center;
  font-family: harabara!important;
  
  
}

.content-desktop {display: block;}
#content-mobile {display: none;}

@media screen and (max-width: 500px) {

.content-desktop {display: none;}
#content-mobile {display: block;}
#mySidepanel .CartComponent{

}

}
#userpoint {
  margin: 0;
  font-size: 11px;
  color: #ff0000;
  font-weight: bold;
  position: absolute;
  bottom: -11px;
  /* right: 5%; */
  left: 0;
}
#namebox {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 81px;
  background-color:#F3F3FF;
  margin-top: 36px;
  color: rgb(161, 143, 143);

  width: 81px;
}
.mobile {
  background: #f7f7f7;
  padding: 0px 0;
}

#menu-bar #menu li.dropdown .dropdown-menu a{
  display:block;
  border-bottom:1px solid #f2f2f2;
}

#menu-bar #menu li.dropdown .dropdown-menu {
    border-radius: 2px;
    border-top:none !important;
    border-bottom: 4px solid #006;
    padding: 10px;
    width: 219px;
    font-size: 14px;
    max-height: 58vh;
    overflow: hidden;
    overflow-y: scroll;
    transform: none !important;
    top: 35px !important;
}

.sidepanel {
    bottom: 0 !important;
    border-bottom: 5px solid #006;
    width:0px;
}
.searchm {
    margin-top: -14px;
    padding-bottom: 0rem;
    margin-left: 10px;
}
.new{
  border: none;
    background: red;
    font-size: 10px;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    animation: float-txt 2.5s ease-in-out infinit !important;
     box-shadow: 2px 2px 2px rgb(255 255 255 /26%)-2px -2px 5px rgb(o o o /87%);
}
@keyframes float-txt{
  0%{
    transform:translateY(0);
  }
   50%{
    transform:translateY(-75rem);
  }
  100%{
    transform:translateY(0);
  }
}

#category-nav::-webkit-scrollbar, .navbar-collapse::-webkit-scrollbar {
    width: 5px;
    display: none;
    transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;
}
.menu-link:hover #category-nav::-webkit-scrollbar, nav#side_nav:hover  .navbar-collapse::-webkit-scrollbar {
    display: block;
    transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;
}

#category-nav::-webkit-scrollbar-thumb, .navbar-collapse::-webkit-scrollbar-thumb {-webkit-border-radius:2px;-o-border-radius:2px;border-radius:12px; background-color:#000066;transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;} 

#category-nav::-webkit-scrollbar-thumb:hover, .navbar-collapse::-webkit-scrollbar-thumb:hover {-webkit-border-radius:2px;-o-border-radius:2px;border-radius:12px; background-color:#000066;background-image:-webkit-linear-gradient(90deg, transparent, #000066 50%, transparent, transparent);transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;}
 .top{
   position: scroll !important;
 }

 

</style>
